<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">Terms & Conditions</div>
        <div class="time">Effective from: 9 July 2022</div>
      </div>
      <div class="news_detail">
        <div class="text">
          <div class="t2">INTRODUCTION</div>
          <br />1.These terms and conditions govern the purchase and use of the
          following services in relation to the ECI: <br />The following age
          restrictions apply to youth membership: <br />a)Complete Young
          Membership: “Young Innovator” must be aged 31 or under (born on or
          after 24 June 1991). Proof of age (e.g. a copy of your passport or
          driving licence) must be uploaded before completing a “Young
          Innovator” registration. If “Young Innovator” determines at its sole
          discretion that the proof of age provided is not satisfactory, then
          “Young Innovator” reserves the right to upgrade your “Young Innovator”
          membership and charge you for the upgraded membership at the
          prevailing rate. <br />b)Complete Student: Students must be aged 23 or
          under (born on or after 24 June 1999), enrolled in full-time education
          and cannot be/cannot have been employed in the Advertising,
          Communication, Marketing or Design industries. Proof of age (e.g. a
          copy of your passport or driving licence), student ID and an enrolment
          letter must be uploaded before completing a student registration.The
          purchase and use of each Pass shall be subject to these terms and
          conditions to the exclusion of all other terms and conditions of
          business, including any purchase order that the attendee or user may
          provide. <br />2.In circumstances where ECI requires proof that you
          meet certain eligibility criteria as part of an application process，
          ECI reserves the right to approve or reject such applications at its
          absolute discretion. In the event that ECI rejects an application
          (acting reasonably), no refund will be provided. <br />3.All requests
          for a membership are subject to you have completed your form correctly
          in accordance with these terms and conditions <br />a)made the
          requisite pre-payment; or <br />b)provided us with credit/debit card
          details and authorisation to deduct payment; or <br />c)confirmed in
          the online order form that you wish to pay via bank transfer,
          <br />then a confirmation email will be sent to you within 5 working
          days from receipt of your order request. Our sending of a confirmation
          email is acceptance of your order (“Acceptance”) and creates a binding
          contract (a “Confirmed Order”). Save as expressly set out in these
          terms and conditions, you have no right to cancel or revoke a
          Confirmed Order. <br /><br />
          <div class="t2">PRICES AND PAYMENT</div>
          <br />4.Prices are set out on www.eciawards.com (the “Website”).
          Payment may be made in dollars (or such other currency as stipulated
          on the Website) by either: (i) credit or debit card; or (ii) bank
          transfer. If you are paying by credit/debit card, payment must either
          be made, or credit/debit card details and authorization to deduct
          payment given, at the time you submit your order form. If you are
          paying by bank transfer, you must make this clear at the time you
          submit your order form and payment must be made in accordance with
          these terms and conditions. Please inform us at the time of your
          request if you require an invoice in respect of your order. Invoices
          only refer to the currency in which payment has been made.
          <br />5.Prices may change from time to time. If the price increases or
          decreases before you receive a Confirmed Order we will notify you and
          ask you to confirm whether you wish to proceed with your order based
          on the revised price. <br />6.All prices are: <br />a)exclusive of
          sales taxes (including, but not limited to VAT); and <br />b)subject
          to local taxes (where appropriate) which cannot be deducted from any
          payments due. <br />c)Where you receive an Order Rejection we will (to
          the extent that you have made payment) refund your payment within a
          reasonable time from the date of the Order Rejection. <br />d)If you
          wish to pay by bank transfer, please make this clear when completing
          the online order form and submitting it via your My Account area and
          use the bank account details below which correspond to the currency
          you are paying with. You are responsible for paying all bank charges.
          If bank charges are not paid by you your Confirmed Order may be
          cancelled. A payment reference should be included with your payment.
          <br />Credit/Debit Cards - We accept all major credit and debit cards.
          <br />You are able to transfer funds directly into our bank account:
          <br />Bank Account Name: Bank of America <br />Bank Address: 7387 Day
          Creek Blvd. Rancho Cucamonga, CA 91739 <br />ABA Routing Number:
          121000358 <br />WIRE Routing Number: 026009593 <br />SWIFT CODE
          (International): BOFAUS3N <br />
          BOFAUS3NXXX <br />
          Account Name: INTERNATIONAL ENTREPRENEURS, CREATIVES & INNOVATORS
          ASSOCIATION INC <br />Checking Account Number: 325135203088
          <br />Zeller: (909)319-4982 <br />e)Once the transfer has been made,
          you will need to scan and email proof of payment/bank transfer
          confirmation, quoting your company name and REFCL/ order reference
          number, to our Finance Team in order to complete your booking.
          <br />f)If you require the hard copy of your invoice then you must
          request it from our Finance Team by email. We cannot re-issue
          invoices. Invoices only refer to the currency in which payment has
          been made. <br /><br />
          <div class="t2">AUTHORISED USERS</div>
          <br />7.You must always keep your username and password confidential
          as you are responsible for all activities undertaken through your
          Account. <br />8.You agree to log-off from your Account at the end of
          each session and to email us immediately at
          international@eciawards.org if you become aware of any loss, theft or
          unauthorized use or disclosure of your username/password and/or
          Account. We shall not be liable for any loss or damage arising from
          your failure to comply with these requirements. <br />9.The user
          agrees that a single user name and password shall be made available to
          each user, and not made available to multiple users (i.e. no sharing
          of user names and passwords). Breach of this Clause 20 shall be
          considered a material breach of these terms and conditions.
          <br /><br />
          <div class="t2">DATA PROTECTION</div>
          <br />10.General: ECI agrees that it will take appropriate technical
          and organizational measures against the accidental, unlawful or
          unauthorized processing, destruction, loss, damage or disclosure of
          personal data received or used in fulfilling its obligations under
          these terms and conditions. In these terms and conditions personal
          data is as defined by all laws and regulations (as updated, amended or
          replaced from time to time) applicable to such data. <br />11.Privacy
          Policy: Further information about how ECI uses personal data can be
          found in our Privacy & Cookie Policy. <br />12.Communications: We will
          use email addresses provided to us to send important awards，Festival
          information, including but not limited to, speaker announcements,
          program updates and invitations to special festival events, that we
          believe will enhance their festival experience. All will have the
          option to opt out of communications. <br />13.ECI attendee listing: To
          further enable networking among attendees at the festival, we will
          share your name, job title, company name and country or city where you
          are based (the “Guest Information”) with other attendees, third
          parties and sponsors via our secure networking service in the official
          websites. <br />14.You are in control: Attendee’s email address and
          telephone numbers will not be made public through the listing unless
          you are using our referral program, directory service, or other
          function that needs your contacts to be networked. <br />Reservation
          of rights: ECI reserves the right to deny access and remove any
          attendees from the listing for inappropriate use. You agree to use the
          listing respectfully and solely to network, exchange ideas and contact
          details where appropriate. <br /><br />
          <div class="t2">
            CHANGES TO THE FESTIVAL, NAME CHANGES AND CANCELLATION POLICY
          </div>
          <br />15.We reserve the right to: (a) alter the advertised format,
          date, timing, content and/or location of ECI Festival; or (b) to
          cancel ECI Festival, in each case at any time. Where we change the
          format, date, timing, content or location of ECI Festival. You will
          not be entitled to a refund and we shall not be liable to you for any
          other cost or other expenses (including, without limitation, wasted
          costs and expenses) incurred by you as a consequence (including,
          without limitation, travel and accommodation expenses).
          <br />16.Notwithstanding any other provision of these terms and
          conditions, if between the festival period, you provide written notice
          to us accompanied by evidence in form and substance satisfactory to us
          that, as a direct result of COVID-19 (which term includes any mutation
          thereof): <br />a)the government of the country or state where you are
          resident ("Attendee Territory") has closed its border so that you are
          prohibited from travel from or back to Attendee Territory; or
          <br />b)the government of Attendee Territory has issued official
          advice against travel to China; or <br />c)the government of Attendee
          Territory has imposed an unavoidable quarantine on return from travel
          from China; or <br />d)the government or state of Attendee Territory
          has imposed a period of lockdown so that you are prohibited from
          leaving your home except for essential requirements, <br />then you
          will be transferred to the next edition of ECI Festival. <br />Please
          note that the festival Office will review all requests and will
          confirm in writing a decision. <br />17.Notwithstanding any other
          provision of these terms and conditions, if we determine that, as a
          direct result of COVID-19, it has become commercially impractical or
          unfeasible to hold ECI Festival, we will notify you in writing as soon
          as reasonably practicable, and your attendance will be transferred to
          the next edition of ECI Festival. We reserve the right to do this at
          any time and we shall not be liable to you for any other cost or other
          expenses (including, without limitation, wasted costs and expenses)
          incurred by you as a consequence (including, without limitation,
          travel and accommodation expenses). Save as expressly set out in these
          terms and conditions you have no right to cancel or revoke a Confirmed
          Order. <br />18.Unless otherwise permitted by these terms and
          conditions, cancelled membership fee are not eligible for a refund or
          for credit. <br />19.It will be possible to request an upgrade from a
          lower membership to a higher membership (e.g. from a “Youth
          Membership” to a “Individual membership”), from “Upgrade My
          membership” area of our Website. Additional fees will apply to
          upgrades. It will be possible to request a downgrade by emailing
          international@eciawards.com, however no refunds or credit for
          downgrades. <br /><br />
          <div class="t2">FESTIVAL CONTENT</div>
          <br />20.All rights in all presentations, documentation and materials
          published or otherwise made available as part of the festival
          (including but not limited to any audio or audio-visual recording of
          ECI Festival) (“Content”) is owned by us or is included with the
          permission of the owner of the rights. No: (i) photography, filming or
          recording; or (ii) republication, broadcast or other dissemination of
          the Content, is permitted without our prior written consent. You shall
          not distribute, reproduce, modify, store, transfer or in any other way
          use any of the Content (save that use by the relevant attendee for
          internal business purposes shall be permitted) without our prior
          written consent, and in particular (but without limitation) you shall
          not (and shall procure that each of your attendees shall not) without
          our prior written consent: <br />a)upload any Content into any shared
          system; <br />b)include any Content in a database; <br />c)include any
          Content in a website or on any intranet; <br />d)transmit,
          re-circulate or otherwise make available any Content to anyone else;
          <br />e)make any commercial use of the Content whatsoever; or
          <br />f)use Content in any way that might infringe third party rights
          or that may bring us or any of our affiliates into disrepute.
          <br />21.The Content does not necessarily reflect our views or
          opinions. <br />22.Suggestions or advice contained in the Content
          should not be relied upon in place of professional or other advice.
          Whilst we take reasonable care to ensure that the Content created by
          us is accurate and complete, some of it is supplied by third parties
          and we are unable to check its accuracy or completeness. You should
          verify the accuracy of any information (whether supplied by us or
          third parties) before relying on it. The Content is provided on an “as
          is” basis without any warranties of any kind (express or implied). We
          hereby exclude to the fullest extent permitted by law all liabilities,
          costs, claims, damages, losses and/or expenses arising from any
          inaccuracy or omission in the Content or arising from any infringing,
          defamatory or otherwise unlawful material in the Content. <br />23.To
          the extent that any Content is made available by us online we reserve
          the right to suspend or remove access to such Content at any time.
          <br /><br />
          <div class="t2">TROPHIES</div>
          <br />24.Any and all intellectual property in ECI trophies (including
          without limitation all copyright, design rights and trademark rights)
          is the property of ECI , and ECI has exclusive right to reproduce,
          manufacture, copy, and sell the ECI trophies in any size or medium,
          and to distribute or exploit the design of ECI or reproductions of the
          same by gift, sale, re-sale or license. Save as expressly provided
          herein, no reproduction, replica or other copy of the ECI trophies may
          be made or used by any manufacturer, advertiser, organization or
          individual unless it has the prior express written consent or license
          from ECI . <br /><br />
          <div class="t2">CONFLICTING ACTIVITIES</div>
          <br />25.ECI brings together creative views, market experts, global
          brands and tech gurus, along with the awards honoring the industry’s
          game-changing ideas. Organizations present in are asked to share
          responsibility and agree to the following conditions of participation.
          <br />26.By hosting an event in you agree that: <br />a)you shall not,
          directly or indirectly, organize, fund or sponsor any conflicting or
          competing activity in s unless your company/organization is permitted
          to do so for the festival and it is acting in conjunction with ECI ;
          <br />b)you shall not, directly or indirectly, engage in activities
          that are identical or similar to those conducted by s, or that
          otherwise takes commercial advantage, or is parasitic of ECI Festival,
          its brand, trademark or goodwill; <br />c)you shall not entice
          festival attendees, sponsors or speakers away from ECI Festivals with
          the intention of presenting your own competitive commercial activities
          which run contrary to ECI Festivals or are to their detriment, or
          engage them to participate in or support your event or competitive
          commercial activity which undermines or damages ECI Festivals;
          <br />d)you shall not use the name, trademarks or logos of ECI awards
          and festival as business name to promote your event or competitive
          commercial activity without our prior written consent or license
          <br />e)you shall not engage in promotional or branding activities,
          including but not limited to the distribution of leaflets, brochures
          or flyers whether before, during or after ECI Festivals unless agreed
          in writing with ECI . <br />f)We are happy to assist in and support
          your company with the organization and co-ordination of your private
          events. Please email: international@ECIawrds.org for benefits of the
          ECI Events opportunities. <br />g)We reserve the right to procure the
          cancellation of any event that you host or manage, whether before,
          during or after ECI Festivals <br />h)You acknowledge and agree that
          damages to ECI brands, goodwill and reputation are reasonably
          foreseeable consequences of a breach by you <br /><br />
          <div class="t2">FORCE MAJEURE</div>
          <br />27.ECI accepts no liability to you and will not pay any
          compensation where the performance of its obligations is prevented or
          affected directly or indirectly by or as a result of force majeure or
          any circumstances beyond its reasonable control including, but not
          limited to, flood, earthquake, extreme adverse weather conditions,
          natural disasters, other acts of God, acts of terrorism (or threats of
          such acts), fire, failure of electric power, failure of hardware or
          software, cyber security attacks or any collapse of building
          structures. <br /><br />
          <div class="t2">LIMITATION OF LIABILITY</div>
          <br />28.ECI accepts no liability whatsoever for any loss or damage
          suffered by you in connection with the festival or your accommodation,
          including without limitation any loss or damage to any property left
          in your accommodation. <br />29.ECI will not be liable for any
          indirect, consequential or pure economic loss or any loss of profit,
          goodwill or opportunity (whether caused by the negligence of ECI or
          its employees, contractors or agents or otherwise). <br />30.Nothing
          contained in these terms or conditions or in any other document
          referred to or incorporated in it shall be read or construed as
          excluding any liability for death or personal injury caused by ECI
          negligence or liability for fraud or fraudulent misrepresentation or
          for any other liability which cannot be excluded or restricted by law.
          <br />31.You will ensure that we, our staff and our affiliates will
          not suffer or incur any loss, costs, claims or expenses of any kind
          arising from or in connection with any act or omission by you
          (including anyone acting on your behalf) during or otherwise in
          relation to the festival or your accommodation. <br /><br />
          <div class="t2">GENERAL</div>
          <br />32.These terms and conditions shall be governed by, and
          construed in accordance with, the laws of China and the courts of
          China shall have exclusive jurisdiction to settle any dispute which
          may arise out of, under, or in connection with these terms and
          conditions. <br />33.The terms and conditions constitute the entire
          agreement and understanding of the parties relating to the subject
          matter hereof and supersedes any previous agreement in relation to
          such subject matter. <br />34.You acknowledge that in coming to the
          festival and any associated accommodation you have not relied on, and
          shall have no remedy in respect of, any statement, representation,
          warranty, understanding, promise or assurance (whether negligently or
          innocently made) of any person other than as expressly set out in
          these terms and conditions. <br />35.These terms and conditions shall
          not create, nor shall they be construed as creating, any partnership
          or agency relationship between us. <br />36.You accept that
          communication with us may be electronic. We may contact you by e-mail
          or provide you with information by posting notices on our Website. You
          agree to this electronic means of communication and you acknowledge
          that all such communications that we provide to you electronically
          comply with any legal or contractual requirement that such
          communication be made in writing. <br />37.Save as set out expressly
          in these terms and conditions you are not permitted to re-sell,
          transfer, assign or otherwise dispose of any of your rights or
          obligations arising under these terms and conditions. <br />38.ECI may
          update these terms and conditions at any time without prior notice. We
          will post any changes on the Website. It is the user’s responsibility
          to ensure they review these terms and conditions regularly to
          familiarize themselves with any changes. <br />39.If there is a
          dispute between the user and ECI concerning these terms and
          conditions, the parties will use all reasonable endeavors to act
          reasonably and settle the matter as appropriate. Nothing shall prevent
          either party from seeking interim or injunctive relief. <br />40.If
          any provision of these terms and conditions is found to be invalid the
          invalidity of that provision shall not affect the validity of the
          remaining provisions of it, which shall remain valid and enforceable.
          <br />41.No person who is not a party to these terms and conditions,
          shall have any rights under or in connection with it by virtue of the
          Contracts (Rights of Third Parties) Act 1999 and the rights of the
          parties to terminate, rescind or agree any variation, waiver or
          settlement under these terms and conditions shall not be subject to
          the consent of any third party. <br />42.No failure or delay by ECI to
          exercise any right or remedy provided under these terms and conditions
          or by law shall constitute a waiver of that or any other right or
          remedy, nor shall it preclude or restrict the further exercise of that
          or any other right or remedy. No single or partial exercise of any
          right or remedy shall preclude or restrict the further exercise of
          that or any other right or remedy. <br />43.Nothing in these terms and
          conditions restricts the user’s statutory rights as a consumer (where
          applicable). <br /><br />
          <div class="t2">DISCLAIMER</div>
          <br />44.Attendees are informed that all exhibits for the festival are
          strictly for the purpose of criticism and review only and do not
          constitute any recommendation, endorsement or promotion of the
          products or services featured therein by ECI , any of its affiliates,
          or otherwise. Nothing contained in the exhibits represents the views
          or opinions of ECI or its affiliates, or otherwise and no reliance
          should be placed on any part of the exhibits other than for the
          purpose of evaluation as an entry to ECI Festival. ECI and its
          affiliates do not accept liability of any kind in respect of the
          exhibits or any product or service referred to in the exhibits.
          <br /><br />
          <div class="t2">SANCTIONS</div>
          <br />45.ECI is part of an enlarged corporate group which pledges to
          trade legally and respect all laws. ECI operates a Group Sanctions
          Policy which means that ECI cannot receive consideration from
          individuals or organizations based or residing in, or connected with,
          a country or organization which is subject to EU or US Government or
          Chinese sanctions. ECI may refuse to accept an order from or provide
          services or deliverables to any such person or organization for any
          reason. <br /><br />
          <div class="t2">
            SECTION B – ADDITIONAL PROVISIONS APPLYING TO ATTENDANCE
          </div>
          <br /><br />
          <div class="t2">CONDUCT</div>
          <br />46.We ask that all attendees behave respectfully towards their
          fellow attendees and festival staff. Attendees must not do anything
          which: (a) may bring the festival or ECI into disrepute; or (b) be
          prejudicial to the image and/or reputation of the festival or ECI.
          Attendees must: (i) comply with any entry requirements specified by us
          from time to time in relation to the COVID-19 pandemic (including,
          without limitation, proof of vaccination status and COVID-19 testing);
          (ii) comply with all reasonable instructions given by festival staff,
          including without limitation in relation to health and safety; (iii)
          not do anything that would or is likely to endanger other attendees,
          festival staff or the general public; and (iv) comply with all local
          laws, rules and regulations (including, without limitation, alcohol
          licensing laws and rules and regulations on the use of unmanned
          drones). <br />47.Attendees are required to comply with the dress code
          for the festival, which is “Business Casual”. If, in our reasonable
          opinion, an attendee has not complied with the dress code, they may be
          asked to change into more appropriate clothing. Should an attendee
          refuse to comply with the dress code, they may be asked to leave the
          festival. <br />48.By attending the festival, you agree to comply with
          all security measures which are in place in and around the festival
          including, without limitation, any bag checks, metal detectors or
          scanners. <br /><br />
          <div class="t2">AWARDS SHOWS</div>
          <br />49.It is not possible to purchase additional access for
          individual awards shows. Access to award shows will not be possible
          without an invitation letter. <br />50.You must be aged 18 or over to
          access the applicable festival parties. ECI reserves the right to
          check an attendee’s form of identification (e.g. driving license or
          passport) upon arrival at the festival parties and to deny access to
          anybody who is under 18. <br /><br />
          <div class="t2">FILMING AND PHOTOGRAPHY AT The festival</div>
          <br />51.Please note that we may film or take photographs of the
          festival. When we are filming, we will use reasonable endeavors to
          ensure that attendees that may be in shot are aware (for example, by
          putting up filming notices). By entering an area where filming or
          photography is taking place, you are deemed to consent to being filmed
          or photographed. You grant us a license (which means permission) to
          use, edit, reproduce, record, modify, enhance, encode, translate,
          distribute, play, perform, make available to other users, prepare
          derivative works of and to display your image and/or your contribution
          to the film or your photograph (your “Contribution”) (with or without
          advertising and sponsorship) by any media or methods whether now known
          or later developed. This license may be exercised in our sole
          discretion throughout the world at any time. <br />52.You agree that
          this license is: perpetual (which means it lasts forever);
          royalty-free (which means we won't pay for the permission either now
          or in the future); and transferable (which means we can transfer the
          license to others). You agree that we can sub-license this license
          (which means we can grant an equivalent permission to others which may
          include without limitation our group companies and advertisers or
          sponsors). You irrevocably and unconditionally waive, in respect of
          your Contribution, all moral rights to which you may be entitled and
          any broadly equivalent rights in any territory of the world. This
          waiver is made in favor of us but shall also extend to our
          sub-licensees, assignees and successors in title.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
